import '../styles/global.css';
import Auth from "../context/store/Auth";
import AuthGlobal from '../context/store/global';
import { currentDepartment } from "../context/actions";
import {useContext, useEffect} from "react"
import React from 'react';

// eslint-disable-next-line react/prop-types
function ScalingUpApp({ Component, pageProps }) {

    /*const context= useContext(AuthGlobal)

    useEffect(()=>{
        currentDepartment({saySome:"SOME!"}, context.dispatch, (error)=>console.log(error));
    },[])*/

    return (
        <Auth>
            <Component {...pageProps}/>
        </Auth>
    );
}

export default ScalingUpApp;
