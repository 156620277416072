import { SET_CURRENT_USER, SET_CURRENT_DEPARTMENT } from '../actions'
import isEmpty from '../validations/isEmpty'

export default function (state, action) {
    switch (action.type) {
    case SET_CURRENT_USER:
        return {
            ...state,
            isAuthenticated: !isEmpty(action.payload),
            user: action.payload
        }
    case SET_CURRENT_DEPARTMENT:
        return {
            ...state,
            current_department: action.payload
        }
    default:
        return state
    }
}
