import { fetchRequest } from '.'

export const register = ({ name, superior }) => {
    return fetchRequest(`/department/register`, {
        method: 'POST',
        body: JSON.stringify({
            name,
            superior
        })
    })
}

export const getDepartments = () => {
    return fetchRequest(`/department/list`, {
        method: 'GET'
    })
}

export const getEmployees = department => {
    return fetchRequest(`/department/${department}/employees`, {
        method: 'GET'
    })
}

export const getPersonalGoals = department => {
    return fetchRequest(`/department/${department}/personalGoals`, {
        method: 'GET'
    })
}

export const deleteDepartment = department => {
    return fetchRequest(`/department/${department}/delete`, {
        method: 'DELETE'
    })
}

export const updateDepartment = (departmentSlug, name) => {
    return fetchRequest(`/department/${departmentSlug}/update`, {
        method: 'PUT',
        body: JSON.stringify({
            name
        })
    })
}

export const deleteUser = userSlug => {
    return fetchRequest(`/user/${userSlug}/delete`, {
        method: 'DELETE'
    })
}

//updateUser
export const updateUser = (userSlug, first_name, last_name, email, role, password, data) => {
    return fetchRequest(`/user/${userSlug}/update`, {
        method: 'PUT',
        body: JSON.stringify({
            first_name,
            last_name,
            email,
            role,
            password,
            data
        })
    })
}
