import jwt_decode from 'jwt-decode'
import { login } from '../../utils/user-helper'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_CURRENT_DEPARTMENT = 'SET_CURRENT_DEPARTMENT'
import Router from 'next/router'

export const accessUser = async (user, dispatch, seterror) => {
    //login
    const { success, response, error } = await login(user)

    if (success) {
        if (response.error) {
            seterror(response.error)
            logoutUser(dispatch)
        } else {
            const { token } = response
            localStorage.setItem('_su_cu', token)
            const decoded = jwt_decode(token)
            seterror('')
            dispatch(setCurrentUser(decoded))
        }
    }
}

export const setCurrentUser = decoded => {
    //si se loguea , setear datos del usuario
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const logoutUser = dispatch => {
    //logout
    localStorage.removeItem('_su_cu')
    dispatch(setCurrentUser({}))
    location.reload()
}

export const updateUserData = (dispatch, userData, userCont) => {
    const news = {
        ...userCont,
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        photo: userData.photo
    }
    dispatch(setCurrentUser(news))
    userData.token && localStorage.setItem('_su_cu', userData.token)
}

export const currentDepartment = async (department, dispatch, seterror) => {
    seterror('')
    dispatch(setCurrentDepartment(department))
}

export const setCurrentDepartment = department => {
    //si se loguea , setear datos del usuario
    return {
        type: SET_CURRENT_DEPARTMENT,
        payload: department
    }
}
