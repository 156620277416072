import { fetchRequest } from '.';

export const login = ({ email, password }) => {
    return fetchRequest('/auth/signin', {
        method: 'POST',
        body: JSON.stringify({
            email,
            password
        })
    });
};

export const forgot = ({ email }) => {
    return fetchRequest('/auth/forgot-password', {
        method: 'POST',
        body: JSON.stringify({
            email
        })
    });
}

export const register = ({ firstname, lastname, email, department, role }) => {
    return fetchRequest('/user/register', {
        method: 'POST',
        body: JSON.stringify({
            first_name: firstname,
            last_name: lastname,
            email,
            department,
            role
        })
    });
}
export const reset = ({ password }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetPasswordLink = urlParams.get('t');

    return fetchRequest('/auth/reset', {
        method: 'PUT',
        body: JSON.stringify({
            password,
            resetPasswordLink
        })
    });
}

export const updateInfo = (data) => {
    return fetchRequest("/user/update", {
        method: "PUT",
        body: JSON.stringify(data)
    })
}