export const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_URL

const fetchRequest = async (requestPath, requestParams = {}, headers = {}) => {
    try {
        const token = localStorage.getItem('_su_cu')
        const response = await fetch(`${BASE_API_URL}${requestPath}`, {
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
                ...headers
            },
            ...requestParams
        })

        const responseAsJson = await response.json()

        return {
            success: true,
            response: responseAsJson
        }
    } catch (error) {
        return {
            success: false,
            error
        }
    }
}

export default fetchRequest;