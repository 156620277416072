import React, { useContext, useEffect, Fragment } from "react"
import AuthGlobal from '../../context/store/global';
import { currentDepartment } from "../../context/actions";
import { getDepartments } from "../../utils/departments-helper"

const CurrentDepartments = () => {
    const context = useContext(AuthGlobal)

    useEffect(() => {
        async function getAllDepartments(){
            const {success, response, error} = await getDepartments()
            if(success && response){
                currentDepartment(response, context.dispatch, (error) => console.log(error));
            }else{
                currentDepartment([], context.dispatch, (error) => console.log(error));
            }
        }
        getAllDepartments()
    }, [])

    return (
        <Fragment>

        </Fragment>
    )
}

export default CurrentDepartments