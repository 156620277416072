import React, { useReducer, useEffect,useState } from "react";
import authReducer from "../reducers";
import { setCurrentUser } from "../actions";
import AuthGlobal from "./global";
import jwt_decode from "jwt-decode";
import CurrentDepartments from "../actions/CurrentDepartments"

const Auth = props => {
    const [globalState, dispatch] = useReducer(authReducer, {
        isAuthenticated: null,
        user: {},
        current_department: {}
    });
    const [showChild, setShowChild] = useState(false);

    useEffect(() => {
        if (localStorage._su_cu) {
            const decoded = localStorage._su_cu ? localStorage._su_cu : "";
            dispatch(setCurrentUser(jwt_decode(decoded))); 
        }
        setShowChild(true);
    }, []); 

    if (!showChild) {
        return null;
    } else {
        return (
            <AuthGlobal.Provider
                value={{
                    globalState,
                    dispatch
                }}
            >
                <CurrentDepartments></CurrentDepartments>
                {props.children}
            </AuthGlobal.Provider>
        );
    }
};

export default Auth;
